import axios from 'axios'

const request = axios.create({
    baseURL: process.env.VUE_APP_API,
})

request.interceptors.request.use(
    request => {
        const token = localStorage.getItem('btsminer_token')
        if (token) {
            request.headers.Authorization = `Bearer ${token}`;
        }
        return request
    },
    error => {
        return Promise.reject(error)
    }
)

export default request