import {_store} from "../../api/helper";

const state = {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    langStatus: _store.get('lang')
}

const mutations = {
    toggleSidebarDesktop (state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    changeLanguage(state, value){
        state.langStatus = value;
    },
    set (state, [variable, value]) {
        state[variable] = value
    }
}

const actions = {

}

const sidebarModule = {
    namespaced: true,
    state,
    mutations,
    actions
}

export default sidebarModule;