<template>
  <div class="profile text-main_black">
    <h3 class="text-main_black">{{ $t("Profile") }}</h3>
    <CSpinner grow size="lg" v-if="loading" />
    <form action="" v-else>
      <CCard
        class="m-0 w-auto d-inline-block p-2 rounded-pill my-3"
        @click="$refs.file.click()"
      >
        <CImg
          :src="
            imageUrl
              ? imageUrl
              : modalInfo.curUser.image
              ? hostUrl + modalInfo.curUser.image
              : require('@/assets/icons/users/avatar.png')
          "
          shape="rounded-circle"
          class="border-white"
          width="155px"
          height="155px"
        />
        <input type="file" class="d-none" name="image" @change="updateImage" ref="file" />
      </CCard>
      <p class="text-gray">
        {{ $t("Profile photo size") }}:
        <span class="text-main_black">200x200 pixels</span>
      </p>
      <div class="d-flex flex-wrap" v-if="modalInfo.curUser">
        <p class="w-30 mr-3 ml-0">
          <label for="first_name"> {{ $t("First name") }}</label>
          <CInput
            id="first_name"
            class="rounded-lg"
            name="first_name"
            @change="updateValues"
            :value="modalInfo.curUser.first_name"
          />
        </p>
        <p class="w-30 mr-3 ml-0">
          <label for="last_name"> {{ $t("Last name") }}</label>
          <CInput
            id="last_name"
            name="last_name"
            :value="modalInfo.curUser.last_name"
            @change="updateValues"
          />
        </p>
        <div class="w-30 mr-3 ml-0">
          <label for="" v-if="route.name != 'settings' && modalInfo.curUser.role != 'customer'">{{ $t("Farms") }}</label>
          <div class="position-relative farm-select" v-if="route.name != 'settings' && modalInfo.curUser.role != 'customer'">
            <CCard
              @click="collapse3 = !collapse3"
              class="myCard w-100 farmCard shadow-none m-0 p-2 text-black d-flex flex-row justify-content-between align-items-center cursor-pointer"
            >
              <span class="text-black">{{ $t("Farms") }}</span>
              <img src="@/assets/icons/arrow-select.svg" alt="" />
            </CCard>
            <CCollapse
              :show="collapse3"
              class="collapseCard w-100 border-0 position-absolute"
            >
              
              <CCard
                class="farmCard2 w-100 shadow-none p-2 mt-0 mb-0 radio_card"
                style="max-height: 200px;overflow: scroll"
              >
                <p
                  class="d-flex align-items-center relative"
                  v-for="(obj, i) in allFarms"
                  :key="i"
                >
                  <input
                    type="checkbox"
                    :id="'containers' + i"
                    name="farms"
                    :checked="modalInfo.curUser.farms.filter(curObj => {return curObj.farm_id == obj.id }).length > 0 "
                    class="d-inline w-auto"
                    @change="(e) => changeValue(e, obj.id)"
                  />
                  <!--                      <span class="checkmark"></span>-->
                  <label :for="'containers' + i" class="w-100 mb-0 ml-3">{{
                    obj.value
                  }}</label>
                </p>
              </CCard>
            </CCollapse>
          </div>
        </div>
        <p class="w-30 mr-3">
          <label for="mobile"> {{ $t("Mobile") }}</label>
          <CInput
            id="mobile"
            :value="modalInfo.curUser.phone"
            name="phone"
            @change="updateValues"
          />
        </p>
        <p class="w-30 mr-3">
          <label for="email"> {{ $t("Email") }}</label>
          <CInput
            id="email"
            :value="modalInfo.curUser.email"
            name="email"
            @change="updateValues"
          />
        </p>
        <p class="w-30"></p>
        <p class="w-30 mr-3">
          <label for="date_birth">{{ $t("Date of birth") }}</label>
          <Datepicker :format="customFormatter" v-model="modalInfo.date" />
        </p>
        <div v-if="$route.params.id" class="w-30 mr-3">
          <label for="type"> {{ $t("Type") }}</label>
          <div
            class="bg-white text-main_black border-gray_input rounded-lg type_custom overflow-hidden"
          >
            <CCard
              color="white border-0"
              @click="modalInfo.collapseType = !modalInfo.collapseType"
              class="m-0 d-flex flex-row justify-content-between align-items-center cursor-pointer"
            >
              <span>{{ modalInfo.curUser.role }}</span>
              <img src="@/assets/icons/users/arrow_bottom.svg" alt="" width="10px " />
            </CCard>
            <CCollapse :show="modalInfo.collapseType" class="border-0 p-0">
              <CCard class="border-0 p-0 mt-2 mb-0 radio_card">
                <CInputRadioGroup
                  :options="[
                    { value: 'admin', label: 'Admin' },
                    { value: 'operator', label: 'Operator' },
                    {
                      value: 'customer',
                      label: 'Customer',
                      props: { desription: 'description text' },
                    },
                  ]"
                  class="text-gray"
                  :checked="modalInfo.curUser.user.role"
                  name="role"
                  v-on:update:checked="updateValues"
                >
                </CInputRadioGroup>
              </CCard>
            </CCollapse>
          </div>
        </div>
        <div v-if="$route.params.id" class="w-30 mr-3">
          <label for="type">{{ $t("Status") }}</label>
          <div
            class="bg-white text-main_black border-gray_input rounded-lg type_custom overflow-hidden"
          >
            <CCard
              color="white border-0"
              @click="modalInfo.collapseStatus = !modalInfo.collapseStatus"
              class="m-0 d-flex flex-row justify-content-between align-items-center cursor-pointer"
            >
              <span>{{ modalInfo.curUser.status }}</span>
              <img src="@/assets/icons/users/arrow_bottom.svg" alt="" width="10px " />
            </CCard>
            <CCollapse :show="modalInfo.collapseStatus" class="border-0 p-0">
              <CCard class="border-0 p-0 mt-2 mb-0 radio_card">
                <CInputRadioGroup
                  :options="[
                    { value: 'active', label: 'Active' },
                    { value: 'blocked', label: 'Blocked' },
                    {
                      value: 'password_blocked',
                      label: 'Password blocked',
                      props: { desription: 'description text' },
                    },
                  ]"
                  class="text-gray"
                  :checked="modalInfo.curUser.user.status"
                  name="status"
                  v-on:update:checked="updateValues"
                >
                </CInputRadioGroup>
              </CCard>
            </CCollapse>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <CButton
          class="text-uppercase px-4 py-2 rounded-md bg-white border-gray_secondary text-gray"
          @click="goBack"
          >{{ $t("cancel") }}</CButton>
        <CButton
          class="text-uppercase ml-4 px-4 py-2 rounded-md"
          color="main_color"
          @click="updateUser"
          >{{ $t("save changes") }}</CButton
        >
      </div>
    </form>
  </div>
</template>

<script>
import request from "../../plugins/request";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import {mapActions, mapState} from 'vuex';

export default {
  name: "Profile",
  components: {
    Datepicker,
  },
  data() {
    return {
      imageUrl: null,
      hostUrl: process.env.VUE_APP_API,
      collapse3: false,
      route: this.$route,
      modalInfo: {
        curUser: null,
        date: null,
        toDeleteFarms: [],
        toAddFarms: [],
        farmsCache: [],
        collapseType: false,
        collapseStatus: false,
      },
      loading: true,
    };
  },
  created() {
    this.getDetailUser();
    
  },
  watch: {
    // observe date changes
    "modalInfo.date": {
      handler: function (e) {
        if (!e) return;
        this.modalInfo.curUser.date_birth =
          this.modalInfo.date.getFullYear() +
          "-" +
          (this.modalInfo.date.getMonth() + 1) +
          "-" +
          this.modalInfo.date.getDate();
      },
      deep: true,
    },
    "modalInfo.curUser":{
      handler: function(e){
        if(e && this.$route.name !== 'settings'){
          if(e.user.role != 'customer'){
            if(this.allFarms.length === 0)
              this.GET_ALL_FARMS();
          }
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState("miners", ["allFarms"])
  },
  methods: {
    ...mapActions("miners",['GET_ALL_FARMS']),
    // get detail user
    getDetailUser() {
      if (this.$route.params.id)
        request.get(`/api/v1/users/${this.$route.params.id}`).then((res) => {
          this.modalInfo.curUser = {
            ...this.modalInfo.curUser,
            ...res.data.user,
            ...res.data.user.user,
          };
          this.modalInfo.curUser.farms = [...res.data.user.farms];
          this.modalInfo.farmsCache = [...res.data.user.farms];
          this.loading = false;
          this.modalInfo.date = new Date(this.modalInfo.curUser.date_birth);
        });
      else
        this.$store.dispatch("user/GET_USER_INFO").then((res) => {
          this.modalInfo.curUser = {
            ...res.data.message,
            ...res.data.message.user,
            date: new Date(res.data.message.user.date_birth),
          };
          this.loading = false;
          this.modalInfo.date = new Date(this.modalInfo.curUser.date_birth);
        });
    },
    changeValue(e,i){
      const isExist = this.modalInfo.curUser.farms.filter((obj) => obj.farm_id == i)
      if(isExist.length === 0){
        this.modalInfo.curUser.farms.push({
          user_id : this.modalInfo.curUser.id,
          farm_id : i
        }); 
      }
      else{
        const indexOfObj = this.modalInfo.curUser.farms
        const new_ar = []
        for(let x = 0;x < indexOfObj.length;x++){
          if(indexOfObj[x].farm_id === i)
            continue
          new_ar.push(indexOfObj[x]);
        }
        this.modalInfo.curUser.farms = [...new_ar];
      }
    },
    // updateImage d
    updateImage(e) {
      const files = e.target.files;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.modalInfo.curUser.file = files[0];
    },
    goBack() {
      this.$router.go(-1);
    },
    validateArrays(){
      
      const farmsCache = this.modalInfo.farmsCache;
      const farms = this.modalInfo.curUser.farms;
      const new_farms = [...farms];
      this.modalInfo.toDeleteFarms = [];
      
      for(let x = 0;x < farmsCache.length;x++){
        let isExist = false;
        for(let y = 0;y < farms.length;y++){
          if(farms[y].farm_id === farmsCache[x].farm_id){
            isExist = true;
            new_farms.splice(y,1);
            continue;
          }
        }
        if(isExist){
          continue;
        }
        else{
          this.modalInfo.toDeleteFarms.push(farmsCache[x]);
        }
      }
      this.modalInfo.toAddFarms = [...new_farms];
    },
    updateUser() {
      const formData = new FormData();
      this.validateArrays();
      Object.keys(this.modalInfo.curUser).forEach((res) => {
        if(res === 'farms'){
          formData.append('farmsToAdd',JSON.stringify(this.modalInfo.toAddFarms));
          formData.append('farmsToDelete',JSON.stringify(this.modalInfo.toDeleteFarms));
        }
        else{
          formData.append(`${res}`, this.modalInfo.curUser[res]);
        }
      });
      formData.delete("image");

      if (this.$route.params.id) {
        formData.append("_method", "PATCH");
        request
          .post(`/api/v1/users/${this.modalInfo.curUser.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.$router.push('/users/1?per_page=10');
            this.getDetailUser();
          });
      } else {
        request
          .post(`/api/userInfo`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.getDetailUser();
            this.$router.push("/users");
          });
      }
    },
    updateValues(value, e) {
      // modalInfo current user listen changes
      this.modalInfo.curUser = {
        ...this.modalInfo.curUser,
        [e.target.name]: value,
      };
    },
    customFormatter(date) {
      // custom formatter
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped></style>
