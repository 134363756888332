import 'babel-polyfill';
import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/index'
import i18n from './i18n'
import {Trans} from "./plugins/Translation";
import axios from "axios";
import VueCompositionApi from '@vue/composition-api';
import CoreuiVueCharts from '@coreui/vue-chartjs'
Vue.use(CoreuiVueCharts)

const MyPlugin = {
  install(Vue) {
    Vue.prototype.convertHashrate = (val) => {
      const terraHash = 1000;
      const pettaHash = 1000 * terraHash;
      const exaHash = 1000 * pettaHash;
      if((val / exaHash) >= 1){
        return (val / exaHash) + "Eh/s" 
      }
      else if((val / pettaHash) >= 1){
        return (val / pettaHash) + " Ph/s"
      }
      else if((val / terraHash) >= 1) {
        return (val / terraHash) + " Th/s"
      }
      else{
        return val + " Gh/s"
      }
    }
  },
}

// Vue uses
Vue.use(CoreuiVue);
Vue.use(VueCompositionApi);
Vue.use(MyPlugin);

// performance
Vue.config.performance = true

// proto add helper functions
Vue.prototype.$log = console.log.bind(console);
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(this);

axios.interceptors.request.use(request => {
  request.headers['Accept'] = 'application/json';
  return request;
});

new Vue({
  el: '#app',
  router,
  store,
  icons,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})

