import Vue from 'vue'
import Router from 'vue-router'
import Profile from "../containers/Users/Profile";

// Containers
const TheContainer = () => import('@/containers/Components/TheContainer')
const Pool = () => import('@/containers/Users/Pools');
const Worker = () => import('@/containers/Users/Worker');
const Container = () => import('@/containers/Layers/Container')
const Farms = () => import('@/containers/Layers/Farms')
const Miners = () => import('@/containers/Layers/Miners')
const Reports = () => import('@/containers/Layers/Reports')
const Users = () => import('@/containers/Users/Users')
const Agents = () => import('@/containers/Layers/Agents')
const Support = () => import('@/containers/Layers/Support')
const MinersMonitoring = () => import('@/containers/Layers/MinersMonitoring')
const Settings = () => import('@/containers/Layers/Settings')
const Statistics = () => import('@/containers/Layers/Statistics')

const Recovery = () => import('@/views/pages/Recovery');

const Status = () => import('@/views/pages/Status');

const ResetPasword = () => import('@/views/pages/Reset_password');
// Views
const Dashboard = () => import('@/containers/Layers/Farms')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
// const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Miners
const AddMiner = () => import('@/containers/AddDirectory/AddMiner')
const AddMiners = () => import('@/containers/AddDirectory/AddMiners')
const MinerInfo = () => import('@/containers/Layers/MinerInfo')


// Tickets
const Tickets = () => import('@/containers/Tickets')

// Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: 'Farms/1',
    name: 'Home',
    component: TheContainer,
    children: [
      {
        path: 'dashboard/:id',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'Farms/:id',
        name: 'farms',
        component: Farms
      },
      {
        path: 'profile/:id',
        name: 'profile',
        component: Profile
      },
      {
        path: 'Container/:id',
        name: 'container',
        props: {
          state: false
        },
        component: Container
      },
      {
        path: 'AddMiner',
        name: 'AddMiner',
        component: AddMiner,
      },
      {
        path: 'AddMiners',
        name: 'AddMiners',
        component: AddMiners,
      },
      {
        path: 'MinerInfo/:id',
        name: 'MinerInfo',
        component: MinerInfo
      },
      {
        path: 'Tickets/:id',
        name: 'Tickets',
        component: Tickets,
      },
      {
        path: 'Miners/:id',
        name: 'miners',
        component: Miners
      },
      {
        path: 'Reports',
        name: 'reports',
        component: Reports
      },
      {
        path: 'Statistics',
        name: 'statistics',
        component: Statistics
      },
      {
        path: 'Pool/:id',
        name: 'Pool',
        component: Pool
      },
      {
        path: 'Worker/:id',
        name: 'Worker',
        component: Worker
      },
      {
        path: 'Users/:id',
        name: 'users',
        props: {
          state: false
        },
        component: Users
      },
      // {
      //   path: 'Agents',
      //   name: 'agents',
      //   component: Agents
      // },
      {
        path: 'Support',
        name: 'support',
        component: Support
      },
      {
        path: 'MinersMonitoring',
        name: 'minersMonitoring',
        component: MinersMonitoring
      },
      {
        path: 'Settings',
        name: 'settings',
        component: Settings
      },
      {
        path: 'theme',
        redirect: '/theme/colors',
        name: 'Theme',
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: 'colors',
            name: 'Colors',
            component: Colors
          },
          {
            path: 'typography',
            name: 'Typography',
            component: Typography
          }
        ]
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets
      },
      // {
      //   path: 'users',
      //   meta: {
      //     label: 'Users'
      //   },
      //   component: {
      //     render(c) {
      //       return c('router-view')
      //     }
      //   },
      //   children: [
      //     {
      //       path: '',
      //       name: 'Users',
      //       component: Users
      //     },
      //     {
      //       path: ':id',
      //       meta: {
      //         label: 'User Details'
      //       },
      //       name: 'User',
      //       component: User
      //     }
      //   ]
      // },
      {
        path: 'base',
        redirect: '/base/cards',
        name: 'Base',
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: 'cards',
            name: 'Cards',
            component: Cards
          },
          {
            path: 'forms',
            name: 'Forms',
            component: Forms
          },
          {
            path: 'switches',
            name: 'Switches',
            component: Switches
          },
          {
            path: 'tables',
            name: 'Tables',
            component: Tables
          },
          {
            path: 'tabs',
            name: 'Tabs',
            component: Tabs
          },
          {
            path: 'breadcrumbs',
            name: 'Breadcrumbs',
            component: Breadcrumbs
          },
          {
            path: 'carousels',
            name: 'Carousels',
            component: Carousels
          },
          {
            path: 'collapses',
            name: 'Collapses',
            component: Collapses
          },
          {
            path: 'jumbotrons',
            name: 'Jumbotrons',
            component: Jumbotrons
          },
          {
            path: 'list-groups',
            name: 'List Groups',
            component: ListGroups
          },
          {
            path: 'navs',
            name: 'Navs',
            component: Navs
          },
          {
            path: 'navbars',
            name: 'Navbars',
            component: Navbars
          },
          {
            path: 'paginations',
            name: 'Paginations',
            component: Paginations
          },
          {
            path: 'popovers',
            name: 'Popovers',
            component: Popovers
          },
          {
            path: 'progress-bars',
            name: 'Progress Bars',
            component: ProgressBars
          },
          {
            path: 'tooltips',
            name: 'Tooltips',
            component: Tooltips
          }
        ]
      },
      {
        path: 'buttons',
        redirect: '/buttons/standard-buttons',
        name: 'Buttons',
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: 'standard-buttons',
            name: 'Standard Buttons',
            component: StandardButtons
          },
          {
            path: 'button-groups',
            name: 'Button Groups',
            component: ButtonGroups
          },
          {
            path: 'dropdowns',
            name: 'Dropdowns',
            component: Dropdowns
          },
          {
            path: 'brand-buttons',
            name: 'Brand Buttons',
            component: BrandButtons
          }
        ]
      },
      {
        path: 'icons',
        redirect: '/icons/coreui-icons',
        name: 'CoreUI Icons',
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: 'coreui-icons',
            name: 'Icons library',
            component: CoreUIIcons
          },
          {
            path: 'brands',
            name: 'Brands',
            component: Brands
          },
          {
            path: 'flags',
            name: 'Flags',
            component: Flags
          }
        ]
      },
      {
        path: 'notifications',
        redirect: '/notifications/alerts',
        name: 'Notifications',
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: 'alerts',
            name: 'Alerts',
            component: Alerts
          },
          {
            path: 'badges',
            name: 'Badges',
            component: Badges
          },
          {
            path: 'modals',
            name: 'Modals',
            component: Modals
          }
        ]
      }
    ]
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '500',
        name: 'Page500',
        component: Page500
      },
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          public: true
        }
      },
      {
        path: 'recovery',
        name: 'recovery',
        component: Recovery,
        meta: {
          public: true
        }
      },
      {
        path: 'reset/:token',
        name: 'reset',
        component: ResetPasword,
        meta: {
          public: true
        }
      },
      {
        path: 'status/:status',
        name: 'Status',
        component: Status,
        meta: {
          public: true
        }
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      }
    ]
  }
]

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.public)) {
    next()
  } else {
    if (localStorage.getItem('btsminer_token') === null) {
      next({ name: 'Login' })
    } else {
      next()
    }
  }
})

export default router