export const _store = {
    storage(){
        return window.localStorage;
    },
    get: function (key){
        return this.storage().getItem(key);
    },
    set: function (key,value){
        return this.storage().setItem(key,value);
    },
    remove: function (key){
        this.storage().removeItem(key);
    }
}