import request from "../../plugins/request";
import axios from "axios";

class MinerApi {
    getFarms(page) {
        return request.get('/api/farms?page=' + page);
    }
    getContainers(page) {
        return request.get('/api/containers?page=' + page)
    }
    getPools(page) {
        return request.get('/api/pools?page=' + page)
    }
    getWorkers(page) {
        return request.get('/api/workers?page=' + page)
    }
    getTickets(page) {
        return request.get('/api/tickets?page=' + page);
    }

    deleteFarm(id) {
        return request.delete('/api/farms/' + id);
    }
    postFarm(body) {
        return request.post('/api/farms', body)
    }
    editFarm(id, body) {
        return request.put('/api/farms/' + id, body)
    }
    deleteTicket(id) {
        return request.delete('/api/tickets/' + id);
    }
    postTicket(body) {
        return request.post('/api/tickets', body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    }
    editTicket(id, body) {
        return request.put('/api/tickets/' + id, body)
    }
    getAllFarms() {
        return request.get('/api/farms/all');
    }
    getAllContainers(body = '') {
        return request.get('/api/containers/all?farms=' + body);
    }
    getAllPools() {
        return request.get('/api/pools/all');
    }
    getAllWorkers() {
        return request.get('/api/workers/all');
    }
    getAllCustomers() {
        return request.get('/api/customers/all');
    }
    getMiners(page) {
        if (typeof page == 'object') {
            return request.get(`/api/miners?page=${page.page}&container_id=${page.body}`)
        }
        return request.get('/api/miners?page=' + page)
    }

    deleteContainer(id) {
        return request.delete('/api/containers/' + id)
    }
    changeStatusContainer(id, body) {
        return request.post(`/api/containers/${id}/change-status`, body);
    }
    checkContainer(id) {
        return request.get('/api/containers/check/' + id);
    }
    postContainer(body) {
        return request.post('/api/containers', body);
    }
    editContainer(id, body) {
        return request.put('/api/containers/' + id, body);
    }
    postWorker(body) {
        return request.post('/api/workers', body);
    }
    editWorker(id, body) {
        return request.put('/api/workers/' + id, body);
    }
    postPool(body) {
        return request.post('/api/pools', body);
    }
    editPool(id, body) {
        return request.put('/api/pools/' + id, body);
    }
    getMinerInfo(id) {
        return request.get('/api/miners/' + id);
    }
    changeStatusFarm(id, body) {
        return request.post(`/api/farms/${id}/change-status`, body);
    }
    getMinerComments(miner_id) {
        return request.get(`/api/minerComments/${miner_id}`);
    }
    postMinerComments(body) {
        return request.post(`/api/minerComments`, body);
    }
    getMinerReport(miner_id) {
        return request.get(`/api/minerReport/${miner_id}`);
    }
    getTicket(page) {
        return request.get('/api/tickets/' + page);
    }

    getSections() {
        return request.get(`/api/getSections`);
    }

    getMessages(id) {
        return request.get('/api/ticketMessage/' + id);
    }
    postMessages(body) {
        return request.post('/api/ticketMessage', body);
    }

    getLogs(miner) {
        return request.post(`/api/v1/agent/log_info`, miner);
    }
    getMacAddress(miner) {
        return request.post(`/api/v1/agent/network_info`, miner);
    }

    getCustomers() {
        return request.get('/api/customers/all');
    }
    getWorkersByUserId(id) {
        return request.get('/api/workers/' + id);
    }

    postMiner(miner) {
        return request.post('/api/miners', miner);
    }

    checkMinerIp(body) {
        return request.post('/api/miners/network_info', body);
    }

    getReports(body) {
        return request.post('/api/reports', body);
    }
    getStatistics(body) {
        return request.post('/api/minerReport/statistics', body);
    }

    getMinerChangesLog(id) {
        return request.get('/api/miners/logSevice/' + id);
    }

    getFreeMinerInfo(body) {
        return request.post('/api/miner/free_network_info', body);
    }
    addMultipleMiners(body) {
        return request.post('/api/miners/addMultipleMiners', body);
    }

    updateMiner(id, body) {
        return request.put('/api/miners/' + id, body);
    }

    changePassword(body) {
        return request.post('/api/user/changePassword', body);
    }

    searchUsers(body) {
        return request.get('/api/users/searchFor?userName=' + body);
    }
    searchPool(body) {
        return request.get('/api/pool/searchForPool?poolName=' + body);
    }
    searchWorkers(body) {
        return request.get('/api/worker/searchForWorker?workerName=' + body);
    }
    searchTickets(body) {
        return request.get('/api/ticket/searchForTicket?ticketName=' + body);
    }

    getMinerPools(id) {
        return request.get('/api/miner/getPoolMiner?miner_id=' + id);
    }

    getCountError(id) {
        return request.get('/api/miners/' + id + '/countError')
    }

    changeStatusOfPools(id, body) {
        return request.post(`/api/miners/${id}/change-status`, body);
    }

    async downloadPdf(body) {
        return request.post('/api/reports/pdf', body);
    }
    async downloadXls(body) {
        return axios.post({
            method: 'POST',
            url: 'https://apibts.pinedlab.com/api/reports/xls',
            body: body,
            responseType: 'blob'
        });
    }
}

export default new MinerApi();