import request from "../../plugins/request";

class UserApi{
    getUserInfo(){
        return request.get('/api/userInfo')
    }
    getUsers(body){
        return request.get('/api/v1/users?page=' + body)
    }
    searchUsers(body){
        return request.get('/api/users/searchFor?page=' + body)
    }
}

export default new UserApi();