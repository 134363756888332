import userModule from "./user.module";
import sidebarModule from "./sidebar.module";
import minerModule from "./farm.module";

const modules = {
    modules:{
        user: userModule,
        sidebar: sidebarModule,
        miners: minerModule
    }
}
export default modules;