import userApi from "../../api/requests/userApi";
import constants from "../constants";

const state = {
    userInfo: null,
    users: {
        data: null,
        meta: {
            last_page: 0
        }
    }
}
const computeUrl = (body, page) => {
    const collectKey = { url: page };
    Object.keys(body).map(res => {
        collectKey["url"] += (body[res] !== '' ? `&${res}=${body[res]}` : "")
    });
    return collectKey;
};

const mutations = {
    setUser : function(state, user){
        state.userInfo = {...user};
    },
    setUsers: function(state, data){
        state.users.data = data.users.data.map((obj) => {
            const new_res = { ...obj, ...obj.user };
            delete new_res["user"];
            return new_res;
        });
        state.users.meta = data.users
    }
}

const actions = {
    async [constants.GET_USER_INFO]({commit}){
        try {
            const user = await userApi.getUserInfo();
            commit('setUser',user.data.message);

            return Promise.resolve(user);
        }
        catch (e){
            return Promise.reject(e);
        }
    },
    async [constants.GET_USERS]({commit},{page, body}){
        try{
            const comp = computeUrl(body,page)['url']
            const users = await userApi.getUsers(comp)
            commit('setUsers',users.data)

            return Promise.resolve(users)
        }
        catch(e) {
            return Promise.reject(e)
        }
    },
    async [constants.SEARCH_USER]({commit},{page, body}){
        try{
            const comp = computeUrl(body,page)['url']
            const users = await userApi.searchUsers(comp)
            commit('setUsers',users.data)

            return Promise.resolve(users)
        }
        catch(e) {
            return Promise.reject(e)
        }
    }
}

const userModule = {
    namespaced: true,
    state,
    mutations,
    actions
}

export default userModule;