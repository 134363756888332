const constants = {
    GET_USER_INFO: "GET_USER_INFO",
    GET_FARMS: "GET_FARMS",
    GET_CONTAINERS: "GET_CONTAINERS",
    GET_POOL: "GET_POOL",
    GET_WORKER: "GET_WORKER",
    GET_TICKETS: "GET_TICKETS",
    GET_TICKET: "GET_TICKET",
    GET_MESSAGES: "GET_MESSAGES",
    GET_USERS: "GET_USERS",
    GET_COUNT_ERROR: "GET_COUNT_ERROR",

    ADD_MESSAGE: "ADD_MESSAGE",
    ADD_FARM: "ADD_FARM",
    ADD_CONTAINER: "ADD_CONTAINER",
    ADD_POOL: "ADD_POOL",
    ADD_TICKET: "ADD_TICKET",

    EDIT_FARM: "EDIT_FARM",
    EDIT_POOL: "EDIT_POOL",
    EDIT_TICKET: "EDIT_TICKET",

    DELETE_FARM: "DELETE_FARM",
    DELETE_TICKET: "DELETE_TICKET",

    GET_MINERS: "GET_MINERS",
    GET_ALL_FARMS: "GET_ALL_FARMS",

    APPLY_FILTER_FARM: "APPLY_FILTER_FARM",
    APPLY_FILTER_MINERS: "APPLY_FILTER_MINERS",
    GET_ALL_CONTAINERS: "GET_ALL_CONTAINERS",
    GET_ALL_POOLS: "GET_ALL_POOLS",
    GET_ALL_WORKERS: "GET_ALL_WORKERS",
    GET_ALL_CUSTOMERS: "GET_ALL_CUSTOMERS",
    FETCH_QUICKLY: "FETCH_QUICKLY",
    EDIT_CONTAINER: "EDIT_CONTAINER",
    ADD_WORKER: "ADD_WORKER",
    EDIT_WORKER: "EDIT_WORKER",
    CHECK_CONTAINER: "CHECK_CONTAINER",
    CHANGE_STATUS_CONTAINER: "CHANGE_STATUS_CONTAINER",
    CHANGE_STATUS_FARM: "CHANGE_STATUS_FARM",
    FILTER_FARMS: "FILTER_FARMS",
    GET_MINER_INFO: "GET_MINER_INFO",
    MINER_REPORT: "MINER_REPORT",
    GET_MINER_COMMENTS: "GET_MINER_COMMENTS",
    ADD_COMMENT: "ADD_COMMENT",
    ADD_MULTIPLE_MINERS: "ADD_MULTIPLE_MINERS",
    GET_SECTIONS: "GET_SECTIONS",
    GET_LOGS: "GET_LOGS",
    GET_MACADDRESS: "GET_MACADDRESS",
    GET_OWNERS: "GET_OWNERS",
    GET_WORKERS_BY_USER: "GET_WORKERS_BY_USER",
    ADD_MINER: "ADD_MINER",
    CHECK_CONNECTION: "CHECK_CONNECTION",
    CHECK_MULTIPLE_MAC_ADDRESS: "CHECK_MULTIPLE_MAC_ADDRESS",
    GET_REPORTS: "GET_REPORTS",
    GET_STATISTICS: "GET_STATISTICS",
    GET_MINER_POOLS: "GET_MINER_POOLS",
    GET_MINER_CHANGES: "GET_MINER_CHANGES",

    DOWNLOAD_PDF: "DOWNLOAD_PDF",
    EXCEL_DOWNLOAD: "EXCEL_DOWNLOAD",

    UPDATE_MINER: "UPDATE_MINER",

    CHANGE_PASSWORD: "CHANGE_PASSWORD",

    SEARCH_POOL: "SEARCH_POOL",
    SEARCH_USERS: "SEARCH_USERS",
    SEARCH_USER: "SEARCH_USER",

    SEARCH_WORKER: "SEARCH_WORKER",
    SEARCH_TICKET: "SEARCH_TICKET",

    CHANGE_MINER_STATUS: "CHANGE_MINER_STATUS"
}

export default constants;