import constants from "../constants";
import minerApi from "../../api/requests/minerApi";

const containerTemplator = [
    { info: "Current Hashrate", key: "efficiency", value: '', isHeader: true, _classes: 'header-hashrate', },
    { info: "", key: "container_name", farms: {}, _classes: 'table-none', },
    { info: "Farm", value: '', key: "farm_name" },
    { info: "Exp. Hashrate:", key: "exp_hashrate", value: '', isHashrate: true },
    { info: "Crt. Hashrate:", key: "current_hashrate", value: '', isHashrate: true },
    { info: "Efficiency:", key: 'efficiency', value: '%' },
    { info: "Min Temp:", key: 'min_temperature', value: ' C' },
    { info: "Avg Temp::", value: ' C', key: 'avg_temperature' },
    { info: "Max Temp:", value: ' C', key: 'max_temperature' },
    { info: "Active Miners::", item1: {}, _classes: 'table-none', key: 'green', value: '' },
    { info: "Inactive Miners:", item2: {}, _classes: 'table-none', key: 'red', value: '' },
    { info: "Total Miners:", item3: {}, _classes: 'table-none', key: 'total', value: '' },
    { info: "Power con.:", value: ' Kw*H in 1h', key: 'con_powerrate' },
    { info: "Rejected rate:", value: '%', key: 'rejected_rate' },
    { info: "Connection errors:", value: ' in 24h', _classes: 'table-error', key: 'grey' },
    { allInfo: {} }
];
const minerTemplator = [
    {
        info: "Current Hashrate",
        value: "",
        key: 'efficiency',
        isHeader: true,
        _classes: "header-hashrate",
    },
    { info: "Miners-1", _classes: "table-none", key: 'name' },
    { info: "Farm:", value: "", key: 'farm_name' },
    { info: "Container:", value: "", key: 'container_name' },
    { info: "IP:", value: "", key: 'ip' },
    { info: "Model:", value: "", key: 'model' },
    { info: "Crt Hashrate:", value: "", key: 'crt_hashrate', isHashrate: true },
    { info: "Avg Temp:", value: " C", key: 'avg_temp' },
    { allInfo: {} }
];

const state = {
    allFarms: [],
    allPools: [],
    allCustomers: [],
    allContainers: [],
    allWorkers: [],
    farms: {
        data: null,
        meta: null
    },
    containers: {
        data: [],
        meta: null
    },
    pools: {
        data: [],
        meta: null
    },
    statistics: null,
    tickets: {
        data: [],
        meta: null
    },
    _workers: {
        data: [],
        meta: null
    },
    isEdit: {
        status: '',
        content: {}
    },
    miners: {
        data: [],
        meta: null
    },
    minerInfo: {},
    minerReport: [],
    minerComments: [],
    logs: "",
    sections: [],
    customers: [],
    macAddresses: null,
    workers: [],
    userSearchText: "",
    ticketMessages: []
}

const getters = {
    getMinerReportHashrates(state) {
        const hash = {}
        const hourObj = state.minerReport.map(res => {
            const targetHour = new Date(res.created_at).getHours();
            hash[targetHour] = (hash[targetHour] ? [...hash[targetHour], res] : [res]);
            return {
                hour: targetHour < 10 ? "0" + targetHour: targetHour,
                current_hashrate: res.hashrate,
                expected_temperature: res.exp_temperature,
                expected_hashrate: res.exp_hashrate,
                current_temperature: res.temperature
            }
        }).sort(function(a,b) {
            return +a.hour - +b.hour;
        });
        const currentHour = new Date().getHours();
        const lessThanCurrent = [];
        const moreThanCurrent = [];
        for (let x = 0;x < currentHour;x++) {
            const hours = hourObj.filter(res => +res.hour == x);
            for (let y = 0;y < hours.length;y++) {
                lessThanCurrent.push(hours[y]);
            }
        }
        for (let x = currentHour;x < 24;x++) {
            const hours = hourObj.filter(res => +res.hour == x);
            for (let y = 0;y < hours.length;y++) {
                moreThanCurrent.push(hours[y]);
            }
        }
        return [...moreThanCurrent,...lessThanCurrent];
    },
    getStatisticsContent(state) {
        return state.statistics ? state.statistics.content.map(res => {
            return {
                hour: new Date(res.created_at).getHours(),
                new_date: res.created_at,
                current_hashrate: res.hashrate,
                exp_hashrate: res.exp_hashrate,
                current_temperature: res.temperature,
                exp_temperature: res.exp_temperature,
                efficiency: res.efficiency
            }
        }).sort(function (a, b) {
            return a.hour - b.hour;
        }) : [];
    },
    getStatisticsBrief(state) {
        return state.statistics ? (state.statistics.brief ? state.statistics.brief[0] : null) : null
    },
    getMinersWithTemplate: (state) => {
        return state.miners.data.map((res) => {
            return minerTemplator.map((obj) => {
                if (obj.key === 'name') {
                    return {
                        ...obj,
                        info: res['name'],
                        id: res['id']
                    }
                }
                if (obj.allInfo) {
                    return { ...obj, allInfo: { ...res } };
                }
                return { ...obj, value: res[obj.key] + obj.value };
            })
        })
    },
    getContainersTemplate: (state) => {
        return state.containers.data.map((res) => {
            return containerTemplator.map((obj) => {
                if (obj.key === 'container_name') {
                    return {
                        ...obj,
                        info: res['container_name']
                    }
                }
                if (obj.allInfo) {
                    return { ...obj, allInfo: { ...res } };
                }
                if (obj.idOfContainer < 0) {
                    return {
                        ...obj,
                        idOfContainer: res['id']
                    }
                }
                if (obj.key === 'con_powerrate') {
                    return {
                        ...obj,
                        value: (res['con_powerrate']) + obj.value
                    }
                }
                return { ...obj, value: res[obj.key] + obj.value }
            })
        })
    }
}

const mutations = {
    // Farms mutation with computed efficiency
    setFarms: function (state, farms) {
        state.farms.data = farms.data;
        state.farms.meta = {
            total: farms.total,
            per_page: farms.per_page,
            current_page: farms.current_page,
            last_page: farms.last_page
        };
    },
    setTriggerSearchUser: function (state, text) {
        state.userSearchText = text;
    },
    // set containers with template which uses to set corresponded table
    setContainers: function (state, containers) {
        state.containers.data = [...containers.data];
        state.containers.meta = { ...containers };
    },
    setPools: function (state, pools) {
        state.pools.data = [...pools.data];
        if (pools.meta)
            state.pools.meta = { ...pools.meta };
        else
            state.pools.meta = { ...pools }
    },
    setTickets: function (state, tickets) {
        state.tickets.data = [...tickets.data];
        state.tickets.meta = { ...tickets };
    },
    setWorkers: function (state, _workers) {
        state._workers.data = [..._workers.data];
        state._workers.meta = { ..._workers.meta };
    },
    setMiners: function (state, miners) {
        state.miners.data = [...miners.data];
        state.miners.meta = { ...miners };
    },
    triggerEdit: function (state, content = {}) {
        state.isEdit = {
            status: content.status,
            content: { ...content }
        }
    },
    setTicketMessages: function (state, content = []) {
        state.ticketMessages = content.data;
    },
    setAllFarms: function (state, farms = {}) {
        state.allFarms = [...farms.data];
    },
    setAllPools: function (state, pools = {}) {
        state.allPools = [...pools.data.pools];
    },
    setAllWorkers: function (state, workers = {}) {
        state.allWorkers = [...workers.data]
    },
    setAllCustomers: function (state, customers = {}) {
        state.allCustomers = [...customers.customers];
    },
    setAllContainers: function (state, containers = {}) {
        state.allContainers = [...containers.data]
    },
    setMinerInfo: function (state, minerInfo) {
        state.minerInfo = { ...minerInfo.data }
    },
    setMinerReport: function (state, minerReport) {
        state.minerReport = [...minerReport.data];
    },
    setMinerComments: function (state, minerComments) {
        state.minerComments = [...minerComments.data];
    },
    setAllSections: function (state, sections) {
        state.sections = [...sections.data];
    },
    setLogs: function (state, logs) {
        state.logs = logs;
    },
    setMacAddresses: function (state, macAddresses) {
        state.macAddresses = macAddresses;
    },
    setCustomers: function (state, customers) {
        state.customers = [...customers.customers];
    },
    setUserWorkers: function (state, workers) {
        state.workers = [...workers.data];
    },
    setStatistics: function (state, statistics) {
        state.statistics = { ...statistics.data.data }
    },
    setTicket: function (state, ticket) { }
}

const postRequests = {
    async [constants.ADD_CONTAINER]({ dispatch }, { body, page, pageUrls }) {
        try {
            const res = await minerApi.postContainer(body);
            dispatch(constants.GET_CONTAINERS, actions.computeUrl(pageUrls, page + "?")['url']);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.ADD_POOL]({ dispatch }, { body, page, pageUrls }) {
        try {
            const res = await minerApi.postPool(body);
            dispatch(constants.GET_POOL, { page: actions.computeUrl(pageUrls, page)['url'] });

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.ADD_WORKER]({ dispatch }, { body, page, pageUrls }) {
        try {
            const res = await minerApi.postWorker(body);
            dispatch(constants.GET_WORKER, { page: actions.computeUrl(pageUrls, page)['url'] });

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
}

const actions = {
    computeUrl(body, page) {
        const collectKey = { url: page };
        Object.keys(body).map(res => {
            collectKey["url"] += (body[res] !== '' ? `&${res}=${body[res]}` : "")
        });
        return collectKey;
    },
    async requestBody({ commit, type }, page, func) {
        try {
            const response = await func(page);
            commit(type, response.data);

            return Promise.resolve(response.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.GET_FARMS]({ commit }, page) {
        return actions.requestBody({ commit: commit, type: 'setFarms' },
            page,
            minerApi.getFarms);
    },
    async [constants.GET_TICKETS]({ commit }, page) {
        return actions.requestBody({ commit: commit, type: 'setTickets' },
            page,
            minerApi.getTickets);
    },
    async [constants.GET_MINER_COMMENTS]({ commit }, page) {
        return actions.requestBody({ commit: commit, type: 'setMinerComments' },
            page,
            minerApi.getMinerComments)
    },
    async [constants.GET_SECTIONS]({ commit }, page) {
        return actions.requestBody({ commit: commit, type: 'setAllSections' },
            page,
            minerApi.getSections)
    },
    async [constants.GET_MINER_INFO]({ commit, dispatch }, page) {
        // await dispatch(constants.GET_MINER_COMMENTS, page);

        return actions.requestBody({ commit: commit, type: 'setMinerInfo' },
            page,
            minerApi.getMinerInfo)
    },
    async [constants.MINER_REPORT]({ commit }, miner_id) {
        return actions.requestBody({ commit: commit, type: 'setMinerReport' },
            miner_id,
            minerApi.getMinerReport)
    },
    async [constants.GET_ALL_FARMS]({ commit }, page) {
        return actions.requestBody({ commit: commit, type: 'setAllFarms' },
            page,
            minerApi.getAllFarms)
    },
    async [constants.GET_ALL_CONTAINERS]({ commit }, body) {
        try {
            const response = await minerApi.getAllContainers(body);
            commit('setAllContainers', response.data);

            return Promise.resolve(response.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.GET_ALL_POOLS]({ commit }, page) {
        return actions.requestBody({ commit: commit, type: 'setAllPools' },
            page,
            minerApi.getAllPools)
    },
    async [constants.GET_ALL_WORKERS]({ commit }, page) {
        return actions.requestBody({ commit: commit, type: 'setAllWorkers' },
            page,
            minerApi.getAllWorkers)
    },
    async [constants.GET_ALL_CUSTOMERS]({ commit }, page) {
        return actions.requestBody({ commit: commit, type: 'setAllCustomers' },
            page,
            minerApi.getAllCustomers)
    },
    async [constants.FETCH_QUICKLY]({ dispatch }) {
        const awaitedFarms = await dispatch(constants.GET_ALL_FARMS);
        const awaitedContainers = await dispatch(constants.GET_ALL_CONTAINERS);
        return {
            farms: awaitedFarms.data,
            containers: awaitedContainers.data
        }
    },
    async [constants.GET_MESSAGES]({ commit }, id) {

        return actions.requestBody({ commit: commit, type: 'setTicketMessages' },
            id,
            minerApi.getMessages)
    },
    async [constants.ADD_MESSAGE]({ dispatch }, { body, id }) {
        const res = await minerApi.postMessages(body);
        await dispatch(constants.GET_MESSAGES, id);

        return {
            data: res.data
        }
    },
    async [constants.GET_CONTAINERS]({ commit }, page) {
        return actions.requestBody(
            { commit: commit, type: 'setContainers' },
            page,
            minerApi.getContainers)
    },
    async [constants.GET_POOL]({ commit }, { page, pageUrls = undefined }) {
        if (!pageUrls) {
            return actions.requestBody(
                { commit: commit, type: 'setPools' },
                page,
                minerApi.getPools)
        }
        if (Object.keys(pageUrls).length > 0) {
            return actions.requestBody(
                { commit: commit, type: 'setPools' },
                actions.computeUrl(pageUrls, page)['url'],
                minerApi.getPools
            );
        }
        return actions.requestBody(
            { commit: commit, type: 'setPools' },
            page,
            minerApi.getPools)
    },
    async [constants.GET_TICKET]({ commit }, page) {
        return actions.requestBody(
            { commit: commit, type: "setTicket" },
            page,
            minerApi.getTicket
        )
    },
    async [constants.GET_WORKER]({ commit }, { page, pageUrls }) {
        if (!pageUrls) {
            return actions.requestBody(
                { commit: commit, type: 'setWorkers' },
                page,
                minerApi.getWorkers)
        }
        if (Object.keys(pageUrls).length > 0) {
            return actions.requestBody(
                { commit: commit, type: 'setWorkers' },
                actions.computeUrl(pageUrls, page)['url'],
                minerApi.getWorkers
            );
        }
        return actions.requestBody(
            { commit: commit, type: 'setWorkers' },
            page,
            minerApi.getWorkers)
    },
    async [constants.GET_MINERS]({ commit }, page) {
        if (typeof page == 'object') {
            return actions.requestBody(
                { commit: commit, type: 'setMiners' },
                page,
                minerApi.getMiners
            )
        }
        return actions.requestBody(
            { commit: commit, type: 'setMiners' },
            page,
            minerApi.getMiners)
    },
    async [constants.CHANGE_PASSWORD]({ commit }, body) {
        try {
            const res = await minerApi.changePassword(body);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.GET_LOGS]({ commit }, miner) {
        const res = await minerApi.getLogs(miner);
        commit('setLogs', res.data.data);

        return Promise.resolve(res);
    },
    async [constants.GET_MACADDRESS]({ commit }, miner) {
        const res = await minerApi.getMacAddress(miner);
        commit('setMacAddresses', JSON.parse(res.data.data));

        return Promise.resolve(res);
    },
    async [constants.GET_OWNERS]({ commit }) {
        return actions.requestBody({ commit: commit, type: 'setCustomers' },
            1,
            minerApi.getCustomers)
    },
    async [constants.GET_WORKERS_BY_USER]({ commit }, id) {
        return actions.requestBody({ commit: commit, type: 'setUserWorkers' },
            id,
            minerApi.getWorkersByUserId)
    },
    async [constants.GET_REPORTS]({ commit }, body) {
        try {
            const res = await minerApi.getReports(body);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.GET_STATISTICS]({ commit }, body) {
        try {
            const res = await minerApi.getStatistics(body);

            commit('setStatistics', res);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.GET_MINER_CHANGES]({ commit }, id) {
        try {
            const res = await minerApi.getMinerChangesLog(id);

            return Promise.resolve(res.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.GET_MINER_POOLS]({ commit }, body) {
        try {
            const res = await minerApi.getMinerPools(body);

            return Promise.resolve(res.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.ADD_TICKET]({ dispatch }, body) {
        try {
            const res = await minerApi.postTicket(body);
            dispatch(constants.GET_TICKETS, 1);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.CHANGE_MINER_STATUS]({ dispatch }, { id, body }) {
        try {
            const res = await minerApi.changeStatusOfPools(id, body);

            await dispatch(constants.GET_MINER_INFO, id);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.ADD_COMMENT]({ dispatch }, { id, body }) {
        try {
            const res = await minerApi.postMinerComments(body);
            dispatch(constants.GET_MINER_COMMENTS, id);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.ADD_FARM]({ dispatch }, body) {
        try {
            const res = await minerApi.postFarm(body);
            dispatch(constants.GET_FARMS, 1);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.CHECK_CONNECTION]({ dispatch }, body) {
        const macRes = await dispatch(constants.GET_MACADDRESS, body);
        if (macRes.data.data == '[]') {
            return Promise.reject({
                msg: "IP or MAC address doesn't exist"
            })
        }
        const fetched = JSON.parse(macRes.data.data);


        const res = await minerApi.checkMinerIp({
            mac_address: fetched.macaddr,
            ip_address: fetched.ipaddress
        });

        if (res.data.data) {
            return Promise.reject({
                data: res.data.data,
                msg: "already exist"
            });
        }
        return Promise.resolve(fetched);
    },
    async [constants.ADD_MINER]({ dispatch }, body) {
        try {
            const postMiner = await minerApi.postMiner({ ...body });

            return Promise.resolve(postMiner.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.EDIT_FARM]({ dispatch }, { body, id }) {
        try {
            const res = await minerApi.editFarm(id, body);
            dispatch(constants.GET_FARMS, 1);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.EDIT_TICKET]({ dispatch }, { body, id }) {
        try {
            const res = await minerApi.editTicket(id, body);
            dispatch(constants.GET_TICKETS, 1);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.EDIT_CONTAINER]({ dispatch }, { body, id, page, pageUrls }) {
        try {
            const res = await minerApi.editContainer(id, body);
            dispatch(constants.GET_CONTAINERS, actions.computeUrl(pageUrls, page + "?")['url']);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.EDIT_WORKER]({ dispatch }, { body, id, page, pageUrls }) {
        try {
            const res = await minerApi.editWorker(id, body);
            dispatch(constants.GET_WORKER, { page: actions.computeUrl(pageUrls, page)['url'] });

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.EDIT_POOL]({ dispatch }, { body, id, page, pageUrls }) {
        try {
            const res = await minerApi.editPool(id, body);
            dispatch(constants.GET_POOL, { page: actions.computeUrl(pageUrls, page)['url'] });

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.DOWNLOAD_PDF]({ dispatch }, body) {
        try {
            await minerApi.downloadPdf(body);

            return Promise.resolve();
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.EXCEL_DOWNLOAD]({ dispatch }, body) {
        try {
            const res = await minerApi.downloadXls(body);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.DOWNLOAD_XLS]({ dispatch }, { body }) {

    },

    async [constants.DELETE_FARM]({ dispatch }, id) {
        try {
            const res = await minerApi.deleteFarm(id);
            dispatch(constants.GET_FARMS, 1);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.DELETE_TICKET]({ dispatch }, id) {
        try {
            const res = await minerApi.deleteTicket(id);
            dispatch(constants.GET_TICKETS, 1);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.APPLY_FILTER_FARM]({ dispatch }, { body, page }) {
        const collectKey = actions.computeUrl(body, page);
        await dispatch('GET_CONTAINERS', collectKey['url']);
        await dispatch(constants.GET_ALL_FARMS);

        return Promise.resolve(actions.computeUrl(body, page + "?")['url']);
    },
    async [constants.FILTER_FARMS]({ dispatch }, { body, page }) {
        const collectKey = actions.computeUrl(body, page);
        const farms = await dispatch('GET_FARMS', collectKey['url']);

        return Promise.resolve({ farms: farms, url: actions.computeUrl(body, page + "?")['url'] });
    },
    async [constants.APPLY_FILTER_MINERS]({ dispatch }, { body, page }) {
        const collectKey = actions.computeUrl(body, page);
        dispatch('GET_MINERS', collectKey['url']);

        const fetchAll = await dispatch(constants.FETCH_QUICKLY);

        await dispatch(constants.GET_SECTIONS);

        return Promise.resolve({
            url: actions.computeUrl(body, page + "?")['url'],
            contents: fetchAll
        });
    },

    async [constants.CHECK_CONTAINER]({ dispatch }, id) {
        try {
            const res = await minerApi.checkContainer(id);
            dispatch(constants.CHANGE_STATUS_CONTAINER, {
                body: {
                    status: "inactive"
                },
                id: id
            });

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.CHANGE_STATUS_CONTAINER]({ dispatch }, { body, id }) {
        try {
            const res = await minerApi.changeStatusContainer(id, body);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.SEARCH_POOL]({ commit }, { body }) {
        try {
            const res = await minerApi.searchPool(body.search);
            commit("setPools", res.data.pools);

            return Promise.resolve(res.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.SEARCH_WORKER]({ commit }, { body }) {
        try {
            const res = await minerApi.searchWorkers(body.search);
            commit("setWorkers", res.data);

            return Promise.resolve(res.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    async [constants.SEARCH_USERS]({ dispatch }, body) {
        try {
            const res = await minerApi.searchUsers(body);

            return Promise.resolve(res.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.SEARCH_TICKET]({ commit }, body) {
        try {
            const res = await minerApi.searchTickets(body);

            commit("setTickets", res.data);
            return Promise.resolve(res.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.CHECK_MULTIPLE_MAC_ADDRESS]({ dispatch }, body) {
        try {
            const res = await minerApi.getFreeMinerInfo(body);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.ADD_MULTIPLE_MINERS]({ dispatch }, body) {
        try {
            const res = await minerApi.addMultipleMiners(body);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.CHANGE_STATUS_FARM]({ dispatch }, { body, id, page }) {
        try {
            const res = await minerApi.changeStatusFarm(id, body);
            await dispatch(constants.GET_FARMS, page);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.UPDATE_MINER]({ dispatch }, { id, body }) {
        try {
            const res = await minerApi.updateMiner(id, body);
            await dispatch(constants.GET_MINER_INFO, id);

            return Promise.resolve(res);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },

    async [constants.GET_COUNT_ERROR]({ dispatch }, id) {
        try {
            const res = await minerApi.getCountError(id);

            return Promise.resolve(res.data);
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
}

const minerModule = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions: {
        ...actions,
        ...postRequests
    }
}

export default minerModule;